import React, { useState, useEffect } from 'react';
import useReservations from 'state/reservations';
import Entry from './Entry';
import AddCharge from './AddCharge';
import useStep from '../../../../../../hooks/useStep';
import CustomBaseDialog from 'components/alert/CustomBaseDialog';
import Header from '../components/Header';
import ElectedNoCharge from './ElectedNoCharge';

const Attended = ({ id, open, onSubmit, onClose }) => {
  const [reservations, dispatcher, utils] = useReservations();
  const [reservation, setReservation] = useState(null);
  const [step, previous, next, changeStep] = useStep();

  useEffect(() => {
    const reservation = utils.findOne(id);
    setReservation(reservation);
  }, [reservations, utils]);

  return (
    <CustomBaseDialog
      open={open}
      onClose={onClose}
      dismissible={false}
      header={reservation && <Header reservation={reservation} id={id} withBackButton={step !== 0} onBackPressed={()=>changeStep(0)} loading={reservations.isFetching}/>}
    >      
      <Entry reservation={reservation} dispatcher={dispatcher} next={next} changeStep={changeStep} currentStep={step} onClose={onClose}/>
      <AddCharge reservation={reservation} dispatcher={dispatcher} previous={previous} currentStep={step} />
      <ElectedNoCharge reservation={reservation} dispatcher={dispatcher} changeStep={changeStep} currentStep={step} />
    </CustomBaseDialog>
  );
};

export default Attended;
