import React from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import styled from 'styled-components';
import { BG } from '@etiquette-ui/colors';

const CustomDialog = styled(BaseDialog)`
  .dialog-module_content__2NnHL {
    padding: 0px;

    > div {
      margin: 0px;
    }
  }
  .dialog-module_closeIcon__GDDDy {
    filter: brightness(0) invert(1);
  }
`;

const Body = styled.div`
  padding: 16px 24px;
`;

const HeaderContainer = styled.div`
  padding: 12px 16px;
  background-color: ${BG};
`;


export const CustomBaseDialog = ({open, onSubmit, onClose, maxWidth='386px', children, header, ...rest}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      dismissible={false}
      modalProps={{
        style: {
          content: {
            maxWidth: maxWidth,
            minWidth: 'auto',
            width: '100%',
            borderRadius: '8px'
          }                       
        }
      }}
      {...rest}
    >
      {!!header && (
        <HeaderContainer>
          {header}
        </HeaderContainer>)
      }
      <Body>
        {children}
      </Body>      
    </CustomDialog>
  )
}
export default CustomBaseDialog;