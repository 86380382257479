import React from 'react';
import CustomBaseDialog from 'components/alert/CustomBaseDialog';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import Cancelled from './Cancelled';

const Dialog = styled(CustomBaseDialog)`
  .MuiPaper-root {
    max-width: 80vw;
    max-height: 80vh;
    height: 80vh;
  }
  .dialog-content-container {
    max-height: 90%;
    padding: 15px 5px;
    overflow: scroll;
  }
`;

const Header = styled.h4`
  text-align: left;
  font-weight: 600;
  color: white;
  margin: 0;
`;

const PendingReservations = ({ id, open, onSubmit, onClose }) => {
  const { translate } = useI18n();

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      dismissible={false} 
      maxWidth={'80vw'}
      header={(<Header>{translate('Reservations pending to cancel')}</Header>)}>
        <Cancelled />
    </Dialog>
  );
};

export default PendingReservations;
