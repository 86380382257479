import React from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import PrimaryButton  from '@etiquette-ui/buttons';
import styled from 'styled-components';

const Title = styled.h4`
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    margin: 0;
    color: black;
`;

const P = styled.p`
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin: 0px;
  margin-top: 5px;
`;

const ButtonRow = styled.div`
  gap: 10px;
  display: flex;
  margin-top: -5px;
  justify-content: center;

  .button-container {
    min-width: 150px;
    max-width: 100%;
  }
`;

const TinyModal = styled(BaseDialog)`
  .dialog-module_content__2NnHL{  
    padding: 16px 24px;
    padding-top: 5px;
  }
`;

const InfoDialog = ({ open, onClose, onSubmit, ...rest }) => {
  const {
    title = '', 
    header = '',
    buttonText = '',
    body='',
    foot='',
    showCloseButton= true
  } = rest;

  return (
    <TinyModal
      open={open}
      header={header}
      onClose={onClose}
      showCloseButton={showCloseButton}
      modalProps={{
        style: {
          content: {
            maxWidth: '386px',
            minWidth: 'auto',
            width: '100%',
            borderRadius: '8px'
          }                       
        }
      }}
      footer={
        <ButtonRow>
          {!!buttonText && (
            <div className='button-container' >
              <PrimaryButton onClick={onClose}>{buttonText}</PrimaryButton>
            </div>
          )}
        </ButtonRow>
      }
    >
      <Title>{title}</Title>
      <P>{body}</P>
      {foot && <P>{foot}</P>}
    </TinyModal>
  );
};
export default InfoDialog;