import React, { useEffect, useState } from 'react';
import useReservations from 'state/reservations';
import InfoStep from './components/InfoStep';
import CustomBaseDialog from 'components/alert/CustomBaseDialog';
import Header from './components/Header';

const InfoLink = ({ id, open, onSubmit, onClose }) => {
  const [reservationsData, , utils] = useReservations();
  const [reservation, setReservation] = useState(null);

  useEffect(() => {
    setReservation(utils.findOne(id));
  }, [reservationsData, utils]);

  return (
    <CustomBaseDialog
      open={open}
      onClose={onClose}
      dismissible={false}
      header={reservation && <Header reservation={reservation} id={id} withBackButton={false} />}
    >
      <InfoStep reservation={reservation} id={id} onClose={onClose} />
    </CustomBaseDialog>
  );
};

export default InfoLink;
