import React, { useState, useEffect, useContext } from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import Banner from './Banner';
import Table from 'components/Table';
import useReservations from 'state/reservations';
import { useAlert } from '@etiquette-ui/alert-dialog';
import searchFilter from 'pages/home/pages/Reservations/searchFilter';
import { SearchContext } from 'pages/home/pages/Reservations/providers/search';
import { getToday } from 'utils/date';
import { TABLE_MODES } from '../utils';
import { LOCAL_STATUS_CODE } from 'resources/constants/status';

const Grid = styled.div`
  width: 100%;
  height: 88%;
`;

const Container = styled.div`
  flex: 1;
  min-width: 0;
  height: 87%;
  width: 100vw;
  padding: 0 15px;
  margin-top: 15px;
  overflow: scroll;
  position: relative;

  @media( min-width: 768px ){
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media( min-height: 769px ){
    height: 90%;
  }
`;

const CustomTable = ({statusCode, structure, title, htmlId, mode = TABLE_MODES.FUTURE}) => {
  const { translate, formatDate, translateSeconds} = useI18n();
  const [reservations, dispatcher] = useReservations();
  const [data, setData] = useState([]);
  const alert = useAlert();
  const { search } = useContext(SearchContext);

  useEffect(()=>{
    if(mode === TABLE_MODES.PAST) {
      dispatcher.fetchPast();
      return;
    }
    dispatcher.fetchFuture();
  }, []);

  useEffect(() => {
    const list = Object.keys(reservations.dates)
        .filter((d)=>{
          const date =  d.split('-').join('');
          const today = getToday().split('-').join('');
          if(mode === TABLE_MODES.PAST) return date <= today;
          return date >= today;
        })
        .sort((a, b) => {
          a = a.split('-').join('');
          b = b.split('-').join('');
          if(mode === TABLE_MODES.PAST) return a > b ? -1 : a < b ? 1 : 0;
          return a > b ? 1 : a < b ? -1 : 0;
        })
        .map((key) => reservations.dates[key])
        .reduce((acc, curr) => acc.concat(curr), [])
        .filter(({ localStatusCode }) => localStatusCode === statusCode)
        .filter(({ localStatusCode, link }) => localStatusCode === LOCAL_STATUS_CODE.CREATE || !!link);

    setData(searchFilter(search, list, translate));
  }, [reservations.dates, search, translate]);

  return (
    <Grid withBanner={!!title}>
      {!!title ? <Banner>{translate(title)}</Banner> : <div></div>}
      <Container id={htmlId}>
        {Object.keys(reservations.dates).length > 0 && (
          <Table
            intersectionId={htmlId}
            structure={structure}
            config={{ data, translate, alert, formatDate, translateSeconds }}
            isLoading={reservations.isFetching}
            items={data}
          />
        )}
      </Container>
    </Grid>
  );
};

export default CustomTable;