import React from 'react';
import { LangDropdown } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';

const LangSelector = styled(LangDropdown)`
  position: relative;
  order: 2;

  @media (max-width: 768px) {
    margin-right: 0px;
    order: 1;
  }

  @media (min-width: 424px) {
    min-width: 100px;
  }

  .css-qc6sy-singleValue {
    width: fit-content;
    @media (max-width: 424px) {
      > div:first-child { 
        display: block;
        max-width: 28px;

        div:nth-child(2) {
          display: none;
        }
      }
    }
    @media (max-width: 1000px) {
      span {
        color: black;
      }
    }
    @media (min-width: 1001px) {
      span {
        color: white;
        z-index: inherit;
      }
    }
  }

  .css-1u6jvxx-indicatorContainer,
  .css-43ykx9-indicatorSeparator {    
    @media (max-width: 424px) {
      display: none;
    } 
    @media (max-width: 1000px) {
      svg {
        filter: brightness(0);
      }
    }   
  }
`;

const Lang = () => {
  const { selectedLang, setSelectedLang } = useI18n();

  return (
    <LangSelector
      selectedLang={selectedLang}
      setSelectedLang={setSelectedLang}
      chevronStyles={{
        line: {
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
          stroke: 'white',
        },
      }}
    />
  );
};

export default Lang;