import React, {useState} from 'react';
import CardContainer from 'containers/CardContainer';
import CardLayout from 'containers/CardLayout';
import CardHeader from './components/Header';
import { TableNoDivider } from 'components/Table';
import structure from './structure';
import usePendingCharges from 'state/pendingCharges';
import useI18n from 'i18n/useI18n';
import { useAlert } from '@etiquette-ui/alert-dialog';
import { useEffect } from 'react';
import styled from 'styled-components';
import { TableContainer } from 'containers/TableContainer';

const Layout = styled.div`
  height: 80%;
  margin-top: 85px;

  @media( max-width: 1000px ){
    height: 85%;
    margin-top: 15px;
  }
`;

const CustomCardContainer = styled(CardContainer)`
  height: 100%;

  @media( min-width: 768px ){
    overflow: visible;
  }
`;

const TableStyled = styled(TableNoDivider)`
  position: relative;
  padding: 0px 0px;
  .header {
    padding-right: 0;
  }
  .body {
    padding-right: 0;
  }

  @media( max-width: 768px ){
    width: max-content;
    position: absolute;
    left: 0px;
    right: 0px;
    padding-left: 15px;
    padding-right: 15px;

    .header, .body {
      width: 100%;
    }

    .body {
      overflow-y: scroll;;
    }
  }
`;

const PendingCharges = () => {
  const [charges, dispatcher] = usePendingCharges();
  const [pendingChargesCounter, setPendingChargesCounter] = useState(0);
  const { translate, formatDate } = useI18n();
  const alert = useAlert();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  useEffect(() => {
    setPendingChargesCounter(charges.list.length);
  }, [charges.list]);

  return (
    <Layout>
      <CustomCardContainer>
        <CardLayout>
          <CardHeader pendingChargesCounter={pendingChargesCounter}/>
          <TableContainer>
            <TableStyled
              intersectionId="create-table-container"
              structure={structure}
              config={{ data: charges, translate, alert, formatDate }}
              items={charges.list}
            />
          </TableContainer>
          
        </CardLayout>
      </CustomCardContainer>
    </Layout>
  );
};

export default PendingCharges;
