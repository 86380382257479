import React from 'react';
import styled from 'styled-components';
import { statusColors } from 'resources/constants/colors';
import { BG } from '@etiquette-ui/colors';
import BackSVG from 'resources/img/back-icon.svg';
import useI8n from 'i18n/useI18n';
import { isActionRequired } from 'utils/status';
import { Spinner } from "@etiquette-ui/misc";

const Header = ({ reservation, withBackButton, onBackPressed, loading = false }) => {
  const { translate } = useI8n();
  return (
    <Container>
      <Back className={withBackButton ? 'visible' : ''} src={BackSVG} alt="go back button" onClick={onBackPressed} />
      <TagContainer className={withBackButton ? 'move' : ''} >
        <Status textColor={statusColors(isActionRequired(reservation)).foreground}>{translate(reservation.localStatusCode)}</Status>
      </TagContainer>
      {loading && 
        <Spinner 
          style={{
            '--width': '35px',
            '--margin': '0 5px',
            '--bg': `${BG}`,
            '--color': 'white'
          }}  
        />}
    </Container>
  );
};



const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TagContainer = styled.div`
  transition: margin-left 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  margin: 0;
  border-radius: 5px;
  width: 55%;
  margin-left: 0px;
  padding: 0px 3px;
  &.move {
    margin-left: 40px;
  }
`;

const Status = styled.p`
  text-align: left!important;
  font-weight: 400;
  color: white;
  margin: 0;
`;

const Back = styled.img`
  opacity: 0;
  display: none;
  width: 22px;
  margin-right: 10px;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  cursor: none;
  pointer-events: none;
  &.visible {
    opacity: 1;
    display: block;
    cursor: pointer;
    pointer-events: auto;
  }
`;

export default Header;
